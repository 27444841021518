<template>
  <v-sheet>
    <v-row class="px-3">
      <v-col cols="12" sm="10">
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          depressed
          color="#04BF68"
          dark
          @click="showDialog()"
        >
          Add Slide Image
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-data-table :headers="headers" :items="data" :loading="loading">
          <template v-slot:[`item.image`]="{ item }">
            <v-img
              :src="item.image"
              class="ma-2"
              width="160"
              height="80"
              style="cursor: pointer"
              @click="
                () => {
                  dialogImage = true;
                  urlFile = item.image;
                }
              "
            />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              color="primary"
              fab
              depressed
              x-small
              class="mx-2"
              @click="showDialog(item)"
            >
              <v-icon>mdi-pencil-box-outline</v-icon>
            </v-btn>
            <v-btn
              color="error"
              fab
              depressed
              x-small
              @click="deleteHandler(item)"
            >
              <v-icon>mdi-delete-forever</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogForm" persistent width="600">
      <v-card flat>
        <v-card-title class="grey">
          <div class="heading-4 font-weight-bold">
            {{ isEdit ? "Edit Slide Image" : "Add Slide Image" }}
          </div>
          <v-spacer />
          <v-icon class="font-weight-bold" @click="closeForm()">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="12">
                <div v-if="form.image" class="align-center d-flex flex-column">
                  <v-img
                    :src="form.image"
                    width="500"
                    height="250"
                    style="cursor: pointer"
                    @click="onButtonClick"
                  />
                </div>
                <div v-else class="d-flex align-center justify-center">
                  <div
                    id="upload-image"
                    class="d-flex black--text justify-center align-center"
                    @click="onButtonClick"
                  >
                    Upload Image
                  </div>
                </div>
                <input
                  v-show="false"
                  ref="upload"
                  type="file"
                  accept="image/*"
                  multiple
                  @change="onFileChanged"
                />
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="form.caption"
                  name="image-caption"
                  label="Caption"
                  auto-grow
                  rows="1"
                  maxlength="255"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-divider class="mb-2" />
            <v-row>
              <v-col class="d-flex justify-center">
                <v-btn
                  :loading="loadingDialog"
                  width="150"
                  color="#04BF68"
                  dark
                  depressed
                  @click="save()"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

    <view-image
      :viewFile="dialogImage"
      :urlFile="urlFile"
      @close="dialogImage = false"
    />

    <ModalConfirm
      :dialog="dialogConfrim"
      :loading="loadingDialog"
      title="Delete Slide Image?"
      @action="(r) => deleteAction(r)"
    />
  </v-sheet>
</template>

<script>
import {
  getSlideImages,
  addSlideImage,
  updateSlideImage,
  deleteSlideImage,
} from "@/api/admin/settings";
import { uploadFile, removeFile } from "@/api/file";
import { isURL } from "@/utils/validate";

import ModalConfirm from "@/components/ModalConfirm.vue";
import ViewImage from "../../../components/ViewImage.vue";

export default {
  components: {
    ModalConfirm,
    ViewImage,
  },
  data() {
    return {
      loading: false,
      loadingDialog: false,
      dialogConfrim: false,
      dialogImage: false,
      dialogForm: false,
      data: [],
      urlFile: "",
      deleteData: { account_name: null, id: null, bank: null },
      tempFile: null,
      tempTrashImage: null,
      form: {
        id: null,
        caption: null,
        image: null,
      },
      isEdit: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Caption",
          value: "caption",
        },
        {
          text: "Image",
          value: "image",
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: 200,
        },
      ];
    },
  },
  mounted() {
    this.getSlideImage();
  },
  methods: {
    async deleteAction(param) {
      if (param) {
        this.loadingDialog = true;
        const { id, image } = this.form;

        // delete image from server
        if (isURL(image)) {
          const delImage = await removeFile({ file: image });
          if (!delImage.data.code) this.snackBar(false, "Failed delete image");
        }

        deleteSlideImage({ id })
          .then((res) => {
            const { data } = res;
            this.snackBar(data.code === 1, data.message);
          })
          .catch(() => this.snackBar(false, "something went wrong"))
          .finally(() => {
            this.loadingDialog = false;
            this.dialogConfrim = false;
            this.form = {
              id: null,
              caption: null,
              image: null,
            };
            this.getSlideImage();
          });
      } else this.dialogConfrim = false;
    },
    closeForm() {
      this.getSlideImage();
      this.dialogForm = false;
      this.isEdit = false;
      this.$nextTick(() => {
        this.form.caption = null;
        this.form.image = null;
        this.tempFile = null;
        this.tempTrashImage = null;
        this.$refs.form.reset();
        this.$refs.form.resetValidation();
      });
    },
    showDialog(item) {
      this.dialogForm = true;
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
      if (item) {
        this.form.caption = item.caption;
        this.form.image = item.image;
        this.form.id = item.id;
        this.isEdit = true;
      }
    },
    getSlideImage() {
      this.loading = true;
      getSlideImages(this.query)
        .then((res) => {
          const { data } = res;
          if (data.code) {
            this.data = data.data;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    deleteHandler(item) {
      this.form = item;
      this.dialogConfrim = true;
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    async save() {
      const { caption, id } = this.form;

      if (this.$refs.form.validate()) {
        if (this.form.image) {
          this.loadingDialog = true;

          // Upload image, if exist
          if (this.tempFile) {
            const form = new FormData();
            form.append("file", this.tempFile);

            const uploadImage = await uploadFile(form);
            if (uploadImage.data.code) {
              this.form.image = uploadImage.data.data.path;
            } else this.snackBar(false, "Failed upload image");
          }

          // Delete old image, if exist
          if (this.tempTrashImage) {
            if (isURL(this.tempTrashImage)) {
              const delImage = await removeFile({ file: this.tempTrashImage });
              if (!delImage.data.code)
                this.snackBar(false, "Failed delete image");
            }
          }

          if (this.isEdit) {
            updateSlideImage({ id, image: this.form.image, caption })
              .then((res) => {
                const { code, message } = res.data;
                if (code) this.snackBar(code === 1, message);
              })
              .catch((e) => {
                console.error("updateSlideImage()\n", e);
              })
              .finally(() => {
                this.loadingDialog = false;
                this.closeForm();
              });
          } else {
            addSlideImage({ image: this.form.image, caption })
              .then((res) => {
                const { code, message } = res.data;
                if (code) this.snackBar(code === 1, message);
              })
              .catch((e) => {
                console.error("addSlideImage()\n", e);
              })
              .finally(() => {
                this.loadingDialog = false;
                this.closeForm();
              });
          }
        } else {
          this.snackBar(false, "Image must be fill");
        }
      }
    },
    onButtonClick() {
      this.$refs.upload.click();
    },
    onFileChanged(e) {
      const { image } = this.form;
      if (e.target.files.length > 0) {
        if (isURL(image)) {
          this.tempTrashImage = image;
        }
        const file = e.target.files[0];
        this.tempFile = file;
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (el) => {
          this.form.image = el.target.result;
        };
      }
    },
  },
};
</script>

<style lang="scss">
#upload-image {
  border: 1px solid gray;
  cursor: pointer;
  height: 250px;
  width: 500px;
}
</style>
